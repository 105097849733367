@mixin flex {
    display: flex;
}

@mixin flexAlignCenter {
    @include flex;
    align-items: center;
}
@mixin commonBtn {
    box-shadow: 0px 3px 6px #00000029;
    padding: 8px 19px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-left: 20px;
    background-color: #0069b1;
    color: #fff;
    border: none;
}
/*-----mixin end------*/
.layouPadd {
    width: 100%;
    padding: 20px;
    background-color: #f6f7f8;
}

/*----commonheader----*/
.headerMain {
    border: none;
    margin-bottom: 30px;
    :global(.nav-item) {
        font-size: 16px;
        padding: 0;
        padding-bottom: 10px;
        margin-right: 30px;
        color: #0069b1;
        &:global(.active) {
            font-weight: 700;
            background-color: unset;
            border: none;
            border-bottom: 5px solid #0069b1;
            color: #0069b1;
        }
        &:hover {
            text-decoration: none;
            border: none;
            border-bottom: 5px solid #0069b1;
            outline: unset;
        }
        &:focus-visible {
            outline: unset;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
/*----commonbody----*/
.overallMain {
    .topsection {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        h6 {
            font-weight: 700;
            font-size: 18px;
            color: #172b4c;
            margin-bottom: 0px;
        }
        .tSec {
            .btn1 {
                :global(.MuiButtonBase-root) {
                    @include commonBtn;
                    margin: 0;
                    border: 1px solid #0069b1;

                    padding: 8px 39px;
                }
                &.active {
                    :global(.MuiButtonBase-root) {
                        border: 1px solid #0069b1;
                        color: #0069b1;
                        background-color: #fff;
                    }
                }
                &.hide {
                    :global(.MuiButtonBase-root) {
                        display: none;
                    }
                }
                &.rightB {
                    :global(.MuiButtonBase-root) {
                        margin-left: 20px;
                        padding: 8px 30px;
                    }
                }
            }
            .btn {
                @include commonBtn;
                &:first-child {
                    margin: 0;
                }
                &.active {
                    border: 1px solid #0069b1;
                    color: #0069b1;
                    background-color: #fff;
                }
                &.hide {
                    display: none;
                }
                .icons {
                    width: 16px;
                    margin-right: 15px;
                    fill: #0069b1;
                }
            }
            .search {
                display: flex;
                align-items: center;
                background: #ffffff;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 10px;
                padding: 6px 12px;
                font-size: 16px;
                color: #bbbbbb;
            }
        }
    }
    .subBody {
        display: flex;
        .leftSection {
            width: 100%;
            max-width: 250px;
            margin-right: 20px;
            .blocks {
                box-shadow: 0px 13px 16px #00000029;
                border-radius: 10px;
                background: #0069b1;
                padding: 20px;
                margin-bottom: 20px;
                cursor: pointer;
                .sec1 {
                    display: flex;
                    justify-content: end;
                    font-size: 20px;
                    font-weight: 500;
                    margin-bottom: 13px;
                    color: #fff;
                    span {
                        font-size: 20px;
                        font-weight: 500;
                        // margin-top: -5px;
                    }
                }
                .sec2 {
                    display: flex;
                    flex-direction: column;
                    .icons {
                        margin-bottom: 10px;
                        fill: #fff;
                    }
                    h6 {
                        font-size: 16px;
                        font-weight: 500;
                        margin: 0;
                        color: #fff;
                    }
                }
                &.active,
                &.hoursB {
                    background-color: #fff;
                    .sec1 {
                        color: #172b4c;
                        span {
                            color: #000;
                        }
                    }
                    .sec2 {
                        .icons {
                            fill: #0069b1;
                        }
                        h6 {
                            color: #172b4c;
                        }
                    }
                }
            }
        }
        .rightSection {
            width: 100%;
        }
    }
}
/*----commontable----*/
.mainTable {
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    height: calc(100vh - 173px);
    overflow-y: auto;
    padding: 0 30px;
    .tableStart {
        .headT {
            font-size: 16px;
            font-weight: 500;
            color: #172b4c;
            border-bottom: 3px solid rgba(224, 224, 224, 1);
            vertical-align: baseline;
            &.ActionsT {
                text-align: right;
            }
            &.head1 {
                width: 145px;
            }
            &.head2 {
                width: 130px;
            }
        }
        .bodyT {
            font-size: 16px;
            padding: 40px 16px;
            color: #212529;
            &.firsts {
                padding-left: 40px;
            }
            .mainProgess {
                @include flexAlignCenter;
                justify-content: space-between;
                margin-bottom: 8px;
                .upTo {
                    font-size: 16px;
                    color: #10182066;
                }
                .Rprogress {
                    font-size: 16px;
                    font-weight: 500;
                    color: #212529;
                }
            }

            .Progress {
                height: 10px;
                border-radius: 10px;
                background-color: #efefef;
                :global(.MuiLinearProgress-bar) {
                    border-radius: 10px;
                }
                &.barColorEnc-0 {
                    :global(.MuiLinearProgress-barColorPrimary) {
                        background-color: #ff6900;
                    }
                }
                &.barColorEnc-1 {
                    :global(.MuiLinearProgress-barColorPrimary) {
                        background-color: #008264;
                    }
                }
                &.barColorEnc-2 {
                    :global(.MuiLinearProgress-barColorPrimary) {
                        background-color: #1dbfc1;
                    }
                }
                &.barColorEnc-3 {
                    :global(.MuiLinearProgress-barColorPrimary) {
                        background-color: #ebbc4e;
                    }
                }
                &.barColorEnc-4 {
                    :global(.MuiLinearProgress-barColorPrimary) {
                        background-color: #c8102e;
                    }
                }

                &.barColorHours-0 {
                    :global(.MuiLinearProgress-barColorPrimary) {
                        background-color: #ff6900;
                    }
                }
                &.barColorHours-1 {
                    :global(.MuiLinearProgress-barColorPrimary) {
                        background-color: #00bab3;
                    }
                }
            }
            :global(.MuiCheckbox-colorPrimary.Mui-checked) {
                color: #00bab3;
            }
        }
    }
}

/*--------commonModel------------*/
.mainModel {
    display: flex;
    align-items: center;
}
.mainModelssub {
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 20px;
    h6 {
        font-size: 16px;
        color: #172b4c;
        font-weight: 700;
    }
    .mainDate {
        margin: 30px 0;
        strong {
            font-weight: 500;
            color: #172b4c;
        }
        .date {
            strong {
                font-weight: 500;
                color: #172b4c;
            }
            .datepick {
                background: #ffffff;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 10px;
                padding: 7px;
                &.dateC {
                    font-size: 14px;
                    color: #212529;
                    border: none;
                }
                :global(.MuiInputBase-root) {
                    font-size: 16px;
                    color: #212529;
                    &:global(.MuiInput-underline:before) {
                        display: none;
                    }
                    &:global(.MuiInput-underline:after) {
                        display: none;
                    }
                    :global(.MuiSvgIcon-root) {
                        fill: #bbbbbb;
                    }
                }
                &.s1 {
                    width: 100px;
                }
            }
            .genderName {
                width: 80px;
            }
            .genderG {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 100%;
                .GenderA {
                    color: #707070;
                }
            }
            .Rgroup {
                margin-right: 15px;
                cursor: pointer;
                display: flex;
                &.categories {
                    margin: 10px 0 0;
                }

                .Labels {
                    margin: 0;
                    font-size: 16px;
                    color: #707070;
                    // &.firstL {
                    //     margin-bottom: 30px;
                    // }
                    :global(.MuiTypography-root) {
                        font-size: 16px;
                        margin-left: 20px;
                        color: #212529;
                    }
                    &:global(.Mui-disabled) {
                        color: #989898;
                    }
                    :global(.MuiFormControlLabel-label.Mui-disabled) {
                        color: #989898;
                    }
                }
            }
            .Error {
                margin-top: 10px;
                color: #dc3545;
                font-size: 16px;
            }
        }
        .sHPI {
            font-size: 12px;
            color: #989898;
            margin: 0;
            margin-left: 10px;
        }
        textarea {
            border: 1px solid #bbbbbb;
            border-radius: 10px;
            height: 130px;
            width: 100%;
            resize: none;
            padding: 10px;
        }
        ::placeholder {
            color: #bbbbbb;
        }
        .genderError {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            color: #c40101;
            font-size: 14px;
            .error_outline_icon {
                width: 15px;
                height: 15px;
                margin-right: 5px;
                margin-top: -1px;
            }
        }
    }
}
/*-----view details common-----*/
.mainModelssub {
    h6 {
        margin-bottom: 30px;
    }
    .subDetails {
        display: flex;
        margin-bottom: 25px;
        h5 {
            font-size: 16px;
            font-weight: 500;
            width: 150px;
            margin: 0;
        }
        p {
            margin: 0;
            font-size: 16px;
            color: #212529;
        }
    }
}

/*------Dialog box------*/
.dialogMain {
    .DialogName {
        font-size: 18px;
    }
    .disagree {
        font-size: 16px;
        text-transform: capitalize;
        background: #dc3545;
        color: #fff;
        padding: 2px 10px;
        &:hover {
            background: #dc3545;
            color: #fff;
        }
    }
    .agree {
        font-size: 16px;
        text-transform: capitalize;
        background: #0069b1;
        color: #fff;
        padding: 2px 10px;
        &:hover {
            background: #0069b1;
            color: #fff;
        }
    }
}
