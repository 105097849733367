@import "./variables.scss";
.background {
  height: calc(100vh - 60px);
  // height: 100vh;
  position: relative;
  .details {
    color: $sfn-border-color;
  }
  .a_link {
    color: $sso-logintitle-color;
  }
}

.commonInputfields {
  .label_text {
    color: $sfn-link-color;
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    height: 20px;
    display: flex;
    align-items: center;
    // display: none;
  }
  .error_label_text {
    @extend .label_text;
    color: $sfn-error-color;
  }
  .touched_label_text {
    @extend .label_text;
    color: $sfn-btn-orange;
  }
  .text_field {
    margin: 0px 0 5px 0;
    padding: 6px 10px;
    border-radius: 10px;
    // background: $sso-input-bg;
    box-shadow: 0px 3px 6px #00000029;
    -webkit-box-decoration-break: none;
    -o-box-decoration-break: none;
    box-decoration-break: none;
    &:hover {
      border-color: $sfn-btn-orange;
    }
    .visibilityIcons {
      width: 20px;
      height: 20px;
      fill: $sso-anyprofile-shade3;
    }
    :global(.MuiInputBase-root) {
      color: $sfn-link-color;
      font-weight: 500;
      font-size: 15px;
      &:global(.Mui-focused) {
        color: $sfn-common-black;
      }
    }
  }
  .disabled_text_field {
    margin: 0px 0 5px 0;
    padding: 6px 10px;
    border: 2px solid $sfn-inputborder-disabled;
    background: $sfn-inputbackround-disabled;
    // color: $sfn-inputcolor-disabled;
    box-shadow: none;
    -webkit-box-decoration-break: none;
    -o-box-decoration-break: none;
    box-decoration-break: none;
  }
  .touched_text_field {
    @extend .text_field;
    // background: $sso-input-bg;
    border-color: $sfn-btn-orange;
    box-shadow: 0px 3px 6px #00000029;
    -webkit-box-decoration-break: none;
    -o-box-decoration-break: none;
    box-decoration-break: none;
  }
  .error_text_field {
    @extend .text_field;
    border: 1px solid $sfn-error-color;
    background-color: $sfn-common-white;
    box-shadow: none;
    -webkit-box-decoration-break: none;
    -o-box-decoration-break: none;
    box-decoration-break: none;
    &:hover,
    &:focus,
    &:active {
      border-color: $sfn-error-color;
      box-shadow: none;
      -webkit-box-decoration-break: none;
      -o-box-decoration-break: none;
      box-decoration-break: none;
    }
  }
  .helperText {
    @include flexAlignCenter();
    color: $sfn-error-color;
    font-size: 14px;
    .error_outline_icon {
      width: 15px;
      height: 15px;
      margin-right: 5px;
      margin-top: -1px;
    }
  }
  .select_field,
  .select_field > li,
  .select_field > li > a {
    // margin: 0 0 5px 0;
    // padding: 4px 10px;
    // border-radius: 5px;
    // background: $sso-input-bg;
    // box-shadow: none;
    // -webkit-box-decoration-break: none;
    // -o-box-decoration-break: none;
    // box-decoration-break: none;
    font-size: 14px;
    color: #BBBBBB;
    // box-shadow: none;
    box-decoration-break: none;
    &:hover,
    &:focus,
    &:active,
    &::after,
    &::before,
    &::content,
    &:link {
      text-align: center;
      background: $sfn-common-white;
      border-color: $sfn-btn-orange;
      box-shadow: none;
      -webkit-box-decoration-break: none;
      -o-box-decoration-break: none;
      box-decoration-break: none;
    }
    :global(.MuiSelect-select:focus) {
      background-color: unset;
    }
  }
  .error_select_field {
    @extend .select_field;
    border: 1px solid $sfn-error-color;
    background-color: $sfn-common-white;
    box-shadow: none;
    -webkit-box-decoration-break: none;
    -o-box-decoration-break: none;
    box-decoration-break: none;
    &:hover,
    &:focus,
    &:active {
      border-color: $sfn-error-color;
      box-shadow: none;
      -webkit-box-decoration-break: none;
      -o-box-decoration-break: none;
      box-decoration-break: none;
    }
  }
}

/*---------------common switch in app------------------*/

.switch_toggle {
  :global(.MuiSwitch-thumb) {
    height: 12px;
    width: 12px;
    color: $sfn-common-white;
  }
  :global(.MuiSwitch-colorPrimary) {
    color: $sfn-common-white;
    top: 6px;
    left: 7px;
    :global(.MuiSwitch-input) {
      color: $sfn-switch-color;
    }
  }
  :global(.MuiSwitch-switchBase.Mui-checked) {
    transform: translateX(14px);
  }
  :global(.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track) {
    opacity: 1;
    background-color: $sfn-switch-color;
  }
  :global(.MuiSwitch-track) {
    padding: 9px 9px;
    border-radius: 50px;
    background-color: $sfn-link-color;
    opacity: 1;
  }
}

/*---------------End common switch in app------------------*/

.modal_left_back_btn {
  color: black;
  font-size: 14px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
}

/*-------custom checkbox Start--------*/

/* The checkContainer */

.checkContainer {
  position: relative;
  padding-left: 30px;
  padding-top: 2px;
  margin: 0;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $sso-logintitle-color;
}

/* Hide the browser's default checkbox */

.checkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid $sso-custom-checkbox;
  background-color: $sso-common-white;
  border-radius: 3px;
}

/* When the checkbox is checked, add a blue background */

.checkContainer input:checked ~ .checkmark {
  background-color: $ee-button;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.checkContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.checkContainer .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid $sso-common-white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*------Custom checkbox end--------*/

/*------all custom cards before login--------*/

.customloginMain {
  width: 100%;
  max-width: 400px;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  margin-left: 7.5rem;
  background: none;
  .ssoLogo {
    margin-bottom: 40px;
    text-align: center;
    color: $sso-common-white;
    h4 {
      font-weight: 400;
    }
  }
  .colorsCommon {
    height: 5px;
    background: $sso-common-white;
    .color1 {
      background-color: $sso-background-color1;
    }
    .color2 {
      background-color: $sso-background-color2;
    }
    .color3 {
      background-color: $sso-background-color3;
    }
    .color4 {
      background-color: $sso-background-color4;
    }
    .color5 {
      background-color: $sso-background-color5;
    }
  }
  .innercustomloginMain {
    padding: 30px 40px;
    background-color: $sso-common-white;
    border-radius: 0px 0px 7px 7px;
    box-shadow: 0px 16px 26px #00000077;
    border: none;
    .inputFieldicons {
      width: 20px;
      height: 20px;
      fill: $sso-anyprofile-shade3;
    }
    .customDiv {
      margin-bottom: 15px;
    }
    .commonTitle {
      margin-bottom: 30px;
      letter-spacing: -1px;
      text-align: center;
      color: $sso-logintitle-color;
      strong {
        font-weight: 500;
      }
      span {
        font-weight: 300;
      }
    }
    .a_link,
    .details {
      font-size: 12px;
    }
    .a_link_register {
      font-size: 12px;
      color: $sso-button;
      text-decoration: underline;
    }
    .button {
      margin: 40px 0px 12px 0px;
    }
  }
}
.mainTitle {
  font-weight: 600;
}
.feedback_icon {
  width: 60px;
  height: 60px;
  fill: $sfn-btn-orange;
  margin: 0 auto;
}

/*------custom modal card--------*/

.modalCustomcard {
  z-index: 9999;
  :global(.modal-content) {
    padding: 30px 54px;
    border-radius: 0;
    position: relative;
    box-shadow: 0px 4px 4px #00000029;
    .modal_header {
      padding: 0;
      margin-bottom: 35px;
      display: flex;
      align-items: center;
      border-bottom: 0;
      .commonTitle {
        letter-spacing: -1px;
        margin-bottom: 0;
      }
      :global(.close) {
        position: absolute;
        right: 30px;
        top: 30px;
        border: 2px solid $sfn-link-color;
        border-radius: 50%;
        padding: 9px 4px 13px;
        height: 15px;
        display: flex;
        align-items: center;
      }
    }
  }
  :global(.modal-body) {
    padding: 0;
    .commonForms_m_b {
      margin-bottom: 30px;
    }
    .download_link {
      color: $sfn-link-color;
      text-decoration: underline;
      align-items: center;
      .get_app {
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }
    }
    .modal_left_btn {
      color: $sfn-common-black;
      text-decoration: underline;
      font-weight: 500;
      cursor: pointer;
    }
    .modalPrimarymodal {
      .modalBtn {
        :global(.MuiButtonBase-root) {
          padding: 8px 30px;
        }
      }
    }
  }
}

.no_data_message {
  padding: 35vh;
  text-align: center;
}

.header_title {
  font-weight: 600;
  font-size: 20px;
}

/*-------------Custom sub-body of app and table css-----------------*/

.customTablesmain {
  .innerBodytitle {
    font-size: 26px;
    font-weight: 500;
    padding-top: 30px;
    margin-bottom: 10px;
  }
  .customCardmain {
    border: 1px solid $sfn-customCardmain-shade7;
    background-color: $sfn-common-white;
    padding: 25px;
    .customeTablesub {
      box-shadow: none;
      border: 1px solid $sfn-customCardmain-shade7;
      border-radius: 0;
    }
    :global(.MuiTableCell-root) {
      border-bottom: unset;
    }
  }
  :global(.MuiTableHead-root) {
    :global(.MuiTableRow-root) {
      :global(.MuiTableCell-head) {
        background-color: $sfn-customTablehead-shade8;
        font-size: 16px;
        &:global(.MuiTableCell-alignRight) {
          text-align: center;
        }
      }
    }
  }
  :global(.MuiTableBody-root) {
    :global(.MuiTableRow-root) {
      border-bottom: 1px solid $sfn-customCardmain-shade7;
      :global(.MuiTableCell-body) {
        padding-top: 2px;
        padding-bottom: 2px;
        font-size: 14px;
        color: $sfn-customTabletitle-shade9;
        &:global(.MuiTableCell-alignRight) {
          text-align: center;
        }
      }
      &:nth-of-type(odd) {
        background-color: $sfn-common-white;
      }
      &:nth-of-type(even) {
        background-color: $sso-background;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  :global(.MuiIconButton-label) {
    color: $sfn-btn-orange;
    fill: $sfn-btn-orange;
  }
}

/*-------custom user table--------*/

.customUsertble {
  .innerBodytitle {
    font-size: 26px;
    font-weight: 500;
  }
  .containtMainbody {
    border: 1px solid $sfn-customCardmain-shade7;
    background-color: $sfn-common-white;
    padding: 30px;
    .mainEditsub {
      margin-bottom: 40px;
      .commeditTitle {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }
  .commonSmallCardRedHeader {
    height: 5px;
    background: $sfn-btn-orange;
  }
}

.allMainbodyTitle {
  margin-bottom: 30px;
}

/*--------Filter custom common css---------*/

.filter_background {
  background: $sfn-common-white;
  .filter_head {
    background: $sfn-table-filter-head;
    padding: 12px 20px;
    color: $sfn-common-black;
    .filter_text {
      font-size: 16px;
      font-weight: 500;
    }
    .reset_text {
      font-size: 13px;
      font-weight: 500;
      color: $sfn-customTabletitle-shade9;
      cursor: pointer;
    }
  }
  .filter_body {
    padding: 25px 20px 5px;
    border: 1px solid $sfn-customCardmain-shade7;
  }
  .filter_select_field {
    :global(.MuiInputBase-root) {
      margin: 10px 0 20px 0;
      background: transparent;
      color: $sfn-border-color;
      padding: 0px 10px;
    }
  }
  .filter_date_picker_field {
    margin: 5px 0 15px 0;
    padding: 5px 0px 5px 10px;
    border: 1px solid $sfn-input-border;
    :global(.MuiInput-underline) {
      border: none;
    }
    :global(.MuiInput-underline:before) {
      border: none;
      &:hover {
        border: none;
      }
    }
  }
}

/*---------Shipping CHA and requests tables custom css----------*/

.sfn_list_table {
  .sfn_list_table_head {
    background: $sfn-table-filter-head;
    :global(.MuiTableCell-root) {
      padding: 10px 20px;
      font-size: 16px;
    }
  }
  .sfn_list_table_row {
    :global(.MuiTableRow-root) {
      padding: 0;
    }
    :global(.MuiTableCell-root) {
      padding: 5px 20px;
      font-size: 15px;
      font-weight: 600;
    }
    .flag_image {
      width: 16px;
      height: 16px;
    }
  }
  .sfn_list_table_shade {
    background: $sso-background;
  }
  .shipping_lines_photo {
    width: 30px !important;
    height: 30px !important;
  }
}

// Shipment Card
.shipment_card_font {
  font-size: 14px;
  font-weight: 500;
}

// Back Button
.header_back_button {
  border: $sfn-border-color;
}

// Hide the default arrow from table's head
.hide_arrow_table_head {
  :global(.MuiTableSortLabel-icon) {
    display: none;
  }
}

/*--------------select field option custom css --------------*/

:global(.MuiListItem-root.Mui-selected) {
  background-color: #0069B1 !important;
  color: #fff !important;
}

:global(.MuiListItem-button:hover) {
  background-color: #0069B12e !important;
}
:global(.MuiMenuItem-root) {
  font-size: 14px !important;
}
:global(.MuiPopover-paper) {
  border-radius: 0 !important;
}

/*--------------End select field option custom css --------------*/

.table_container_no_scroll {
  :global(.MuiTableContainer-root) {
    overflow-y: hidden;
  }
  .table_no_scroll {
    :global(.MuiTable-root) {
      overflow-y: hidden;
    }
    .table_head_no_scroll {
      background: $sfn-table-filter-head;
      :global(.MuiTableHead-root) {
        overflow-y: hidden;
      }
    }
  }
}

.users_table {
  margin: 0;
  .users_table_head {
    background: $sso-table-head;
    :global(.MuiTableCell-root) {
      padding: 10px 20px;
      font-size: 16px;
      border: 0;
    }
    @extend .hide_arrow_table_head;
    .users_table_sort_arrow_icon {
      width: 16px;
      height: 24px;
      margin-left: 15px;
    }
  }
  .users_table_row {
    border-top: 5px solid $sso-common-dark;
    :global(.MuiTableCell-root) {
      padding: 8px 20px;
      border: 0;
    }
  }
  .users_table_shade {
    background: $sso-background;
  }
  .users_table_checked_shade {
    background: $sfn-selectoption-color;
  }
  .users_table_row_error {
    border: 1px solid $sfn-error-color;
  }
  .users_table_cell_error {
    border-top: 1px solid $sfn-error-color;
  }
}

// Search icon in TextField
.search_icon {
  color: $sfn-search-shade11;
  :global(.MuiSvgIcon-root) {
    width: 20px;
  }
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

/*----------------terms and policy changes---------------*/

.termsPolicy {
  background-color: $sfn-common-white;
  padding: 30px 0;
  .tc_policy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    .goBackLink {
      display: flex;
      align-items: center;
      color: $sfn-download-btn;
      font-weight: 500;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .text_color {
    color: $sfn-common-shade1;
    font-size: 14px;
    margin: 0;
  }
}

// File upload status
.file_upload_pending {
  color: $sfn-pending;
}

.file_upload_success {
  color: $sfn-success;
}

.file_upload_failed {
  color: $sfn-error-color;
}

// hide the component
.display_none {
  display: none;
}

/*----------------Sharpzapp--------------*/
.popupMain {
  :global(.modal-content) {
    padding: 20px;
  }
  .customDiv {
    margin-bottom: 15px;
  }
}
