@import "./utilities/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Rubik:wght@300;400;500;600;700;800&display=swap");
* {
  font-family: "Rubik", sans-serif !important;
}

html {
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    scroll-behavior: smooth;
    background-color: #f6f7f8;
  }
}

/*---------Custom scrollbar starts----------*/

/* width */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #dee2e6;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #979292bf;
  border-radius: 16px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25), inset -2px -2px 2px rgba(0, 0, 0, 0);
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #979292;
}

/*---------Custom scrollbar Ends----------*/
