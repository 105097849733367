
/*-----------------loader starts here-----------------*/

.loader {
  display: inline-block;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background: #000000c4;
  position: fixed;
  transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
  .loaderBox {
    font-size: 40px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    .loaderRound {
      border-right: 0.1em solid currentcolor;
      border-radius: 100%;
      animation: loaderRound 800ms linear infinite;
      &:before,
      &:after {
        content: "";
        width: 0.8em;
        height: 0.8em;
        display: block;
        position: absolute;
        top: calc(50% - 0.4em);
        left: calc(50% - 0.4em);
        border-left: 0.08em solid currentcolor;
        border-radius: 100%;
        animation: loaderRound 400ms linear infinite reverse;
      }
      &:after {
        width: 0.6em;
        height: 0.6em;
        top: calc(50% - 0.3em);
        left: calc(50% - 0.3em);
        border: 0;
        border-right: 0.05em solid currentcolor;
        animation: none;
      }
    }
  }
  @keyframes loaderRound {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
}
